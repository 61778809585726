export default {
	pageTitle: 'EdTech | Department for International Trade',
	pageDescription: `Inspiring minds with edtech. How the UK is reimagining education`,
	heroSectionOne: {
		main: {
			title: 'Inspiring minds with edtech',
			author: 'WRITTEN BY',
			brandPath: 'images/EBCONPRGB.svg',
			content: `
			<p>
				How the UK is reimagining education 
			</p>
		`
		},
		banner: {
			firstImg: 'images/Hero_shutterstock_1731088219.jpg',
			secondImg: 'images/svg/square-gradient.svg'
		},
	},
	sliderSectionOne: {
		headerImgPath: 'images/innovation-is-great.png',
		headerImgAlt: 'Innovation is great img alt',
		transparentBg: true,
		settings: {
			dots: false,
			arrows: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1,
						draggable: true
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1.2,
						slidesToScroll: 1,
						draggable: true,
						arrows: true
					}
				}
			]
		},
		items: [
			{
				img: 'images/homepage_fintech.jpg',
				title: 'Fintech',
				text: 'The advent of open banking and emerging fintech community has shifted the UK towards a pro-innovation mindset.',
				more: {
					label: 'Read more',
					link: '/story/fintech/'
				}
			}, {
				img: 'images/home_cybersecurity.jpg',
				title: 'Cybersecurity',
				text: 'The rise in cyberattacks and identity fraud has made managing our digital identities increasingly ' +
					'challenging. But a wave of UK startups and SMEs is helping to raise awareness in the corporate community.',
				more: {
					label: 'Read more',
					link: '/story/cybersecurity/'
				}
			}, {
				img: 'images/home_smart_cities.jpg',
				title: 'Smart cities',
				text: 'From nuclear fusion and smart buildings to electric vehicles, UK companies are spearheading the clean-tech revolution.',
				more: {
					label: 'Read more',
					link: '/story/smart-cities/'
				}
			}
		]
	},
	cardSectionOne: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: '',
			content: `<p>School curricula at all levels of education tend to evolve at a glacial pace, with content provided 
			by incumbent publishing companies in formats that have barely changed in decades. Governments have kept a tight 
			handle on what gets taught in schools—and for understandable reasons. They see the curriculum as a means of 
			promoting citizenship and national values, equalising standards and aligning teaching to the needs of the labour 
			market. </p>
			<p>But as the 21st-century economy changes with the rise of automation, artificial intelligence (AI) and digital 
			transformation, the status quo is no longer viable. Catherine Cahn, cofounder of edtech startup Twig Education, 
			predicts a future with more high-end jobs requiring creativity and technical skill and more manual jobs at the 
			lower end that require human dexterity. She anticipates a big gap in the middle as once-safe white-collar 
			professions such as legal services and accounting are hollowed out. “This is something all governments must 
			address, otherwise certain countries will pull away from the pack in terms of innovation,” says Ms Cahn.</p>`
		},
		additional: {
			img: 'images/image_2021_02_24T16_38_28_110Z.png',
			comment: {
				text: 'Children often look for educational content outside of school—they read ' +
					'educational comics or watch science videos on YouTube. We wanted to ' +
					'make a consumer-grade platform that could offer this.',
				subtext: 'Catherine Cahn, co-founder of Twig Education'
			}
		},
	},
	cardSectionTwo: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'Trade and investment challenges and opportunities in the post-pandemic world',
			content: `<p>The pandemic has transformed the global trade and investment landscape, 
but offers unique opportunities to agile and resilient businesses in sectors of the future.</p>`
		},
		additional: {
			img: 'images/homepage_chart.jpg',
		},
		more: {
			label: 'Read More',
			link: '/briefing-paper/'
		}
	},
	contentOne: `
		<p>
			To expose students to high-value opportunities, schools and teachers need more tools from a wider range of partners, 
			including experimental startups and SMEs that are using digital technology to improve learning through more engaging 
			media. “Education is lagging in terms of digital innovation,” says Ms Cahn. "There are reasons. The state controls 
			education in a way that doesn’t leave that much room." Yet change is beginning to happen. 
		</p> 
		<p>
			<span>From the back office to the classroom</span>
		</p>
		<p>
			In the past, edtech companies made the biggest inroads in areas such as data analysis and AI—for example, with 
			software that automates marking or lesson planning for teachers. Only recently have they started using digital 
			technology to deliver enhanced educational experiences. 
		</p>
		<p>
			Twig Education uses video, simulation and imagery to explain key scientific concepts to students. It was founded 
			in 2009 on the premise that the explosion of engaging digital content at the time—the first iPhone had recently 
			launched—could boost learning in young people. "We recognised that children often look for educational content 
			outside of school—they read educational comics or watch science videos on YouTube. We wanted to make a consumer-grade 
			platform that could offer this," recalls Ms Cahn. 
		</p>
		<p>
			Because of the difficulty of changing core curricula, Twig Education, whose staff come from documentary film, 
			journalism, online gaming and consumer technology backgrounds, worked mainly with private schools that were able 
			to invest. Only a clutch of governments came forward to partner in the early days: those of Singapore, Hong Kong 
			and Scotland.  
		</p>
		<p>
			One turning point came in 2012, when the English government pushed through reforms to the science and technology 
			curriculum to incorporate computer science and robotics. This shifted the dial from equating ICT with the use of 
			technology to helping students understand "what is ‘inside the box’, and to think in a more scientific way,” says 
			Caroline Wright, director general of British Educational Suppliers Association (BESA) and director of EdTech 
			Exchange. Those reforms helped UK-based edtech startups, such as Twig Education, “focus on blending learning and 
			technology and bringing subjects to life".
		</p>
		<p>
			Another turning point was the review of science learning standards in the US between <a target="_blank" href="https://www.nextgenscience.org/faqs">
			2011 and 2013</a>. Little adapted 
			in <a target="_blank" href="https://www.nextgenscience.org/science-education-needs-an-update">two decades</a>, 
			there was growing recognition among educators that the science curriculum was "not producing enough 
			graduates going on to study science at college and was too heavily focused on content that you can Google, like the 
			names of the parts of a plant," says Ms Cahn. "That was not in line with what science is today, which is more about 
			data analysis and engineering."
		</p> 
		<p>
			A coalition came together and produced the <a target="_blank" href="https://www.nextgenscience.org/">Next Generation Science Standards</a>,
			 a new approach emphasising the 
			practice of learning science through investigation, problem-solving and iteration, promoting linkages between 
			ideas and disciplines. The standards have now been adopted in <a target="_blank" href="https://www.nextgenscience.org/get-to-know">26 states</a>
			 and are a model for countries around 
			the world. This provided Twig Education with an entry point to support the delivery of core curricula in adopting 
			states, beginning with California in 2018 and expanding to serve a quarter of the Californian elementary school 
			market; a rare example of a UK edtech company prospering over the ocean. 
		</p>
	`,
	contentTwo: `
		<p>
		<span>Trusted global partners</span>
		</p>
		<p>
			Twig Education wants to support the delivery of the core science curriculum, but this is a sensitive policy area 
			given the desire of governments to control the process. The Department for International Trade (DIT) has been a 
			valued partner, helping the company expand globally by providing trust and credibility since Twig Education is 
			not yet on par with the big-brand vendors such as McGraw Hill.
		</p>
		<p>
			According to Ms Cahn, the DIT helps identify like-minded local partners who can help them tailor content to 
			national needs, engage with the school system, account for differing baseline education levels, and ensure 
			examples and case studies are culturally relevant. Twig Education is now reaching nearly 0.5m students and 
			95,727 teachers across 9,080 schools, from Malaysia and Singapore to the Middle East.
		</p>
		<p>
			The UK is also a highly regarded educational brand. “When I travel the world, there is huge respect for the 
			British curriculum and the way it helps children develop their critical thinking, whereby it’s not just about 
			getting answers right but asking, ‘Do you know how to think through more challenging problems?’” says Ms Wright. 
			The central role of UK academic institutions and companies in the development of covid-19 vaccines serve as 
			another reminder of the country’s world-leading science capability. 
		</p>
		<p>
			The UK is nurturing its edtech community through initiatives such as the 
			<a target="_blank" href="https://www.ucl.ac.uk/ioe/departments-and-centres/centres/ucl-knowledge-lab/educate">Educate programme</a>
			, an accelerator based 
			at the University College London (UCL) Institute of Education, which helps startups develop effective technologies 
			based on rigorous research methodologies. More than 200 companies have come through the system so far.
		</p>
		<p>
			Ms Cahn’s hope is that improving engagement with science education will not just help countries improve their 
			economic prospects, but also strengthen global efforts to deal with complex challenges such as climate change. 
			"Digital education content can make the effects of climate change real for students and mobilise the next generation 
			to demand big changes," she says. Lack of scientific literacy has significantly undermined public health 
			communications during the past year, too. "If you look at the anti-vaccination movement, you wish people had 
			had more exposure to science."
		</p>
		<p>
			As governments look to improve science and technology curricula and its long-term contribution to economic growth, 
			Twig Education’s digital content has wider scope than it ever could have predicted. As Ms Cahn says, "We started 
			off asking how we can make children care about the planet and enjoy learning about science. But now this is a question 
			of, ‘Who will be the winners and losers in the next 50 years, the next 70 years, in terms of GDP?’"
		</p>
			`,
	additionalBottomText:
		`<p>Produced for DIT by (E) BrandConnect, a commercial division of The Economist Group, which operates 
		separately from the editorial staffs of The Economist and The Economist Intelligence Unit. Neither (E) BrandConnect 
		nor its affiliates accept any responsibility or liability for reliance by any party on this content.</p>`
}
